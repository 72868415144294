

import 'animate.css'
import {
  createApp
} from 'vue'
import i18n from '@/i18n/index'
import App from './App.vue'
import router from './router'
import store from './store'
// 文档：https://map.heifahaizei.com/doc/index.html
// import BaiduMapOffline from 'vue-baidu-map-offline'
import BaiduMap from 'vue-baidu-map-3x'
import 'dayjs/locale/zh-cn'

import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);

app.config.warnHandler = () => null
app.config.errorHandler = () => null

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
import xss from 'xss'
app.config.globalProperties.$xss=xss
import useSettingStore from '@/store/modules/setting'
import ElementPlusLocaleZhCn from '@/plugins/zh-cn.js'

app.use(ElementPlus, {
  locale: ElementPlusLocaleZhCn,
})
import MapVue from "mapvue";
// app.use(store).use(router).use(ElementPlus).use(i18n).use(BaiduMap, {
//   ak: useSettingStore().ak,
//   v: '3.0',
//   type: 'WebGL'
// }).use(MapVue);
app.use(store).use(router).use(i18n).use(MapVue);

// 在app挂载之前，等待路由加载完毕
router.isReady().then(() => {
  setTimeout(()=>{
    app.mount('#app');


  },1500)
});